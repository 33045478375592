import { Appointment, Patient } from "../models/clinic";
import { AcademicdashboardComponent } from "./academic/academicdashboard/academicdashboard.component";

const Home = {
    text: 'Home',
    link: 'home',
    icon: 'icon-home'
};

const DashboardAdmin = {
    text: 'Dashboard',
    link: 'dashboard/v2',
    icon: 'icon-speedometer'
    // submenu: [
    //     {
    //         text: 'Dashbord v1',
    //         link: '/dashboard/v1'
    //     },
    //     {
    //         text: 'Dashbord v2',
    //         link: '/dashboard/v2'
    //     },
    //     {
    //         text: 'Dashbord v3',
    //         link: '/dashboard/v3'
    //     }
    // ]
};

const DashboardDoctor = {
    text: 'Dashboard',
    link: 'dashboard/v1',
    icon: 'icon-speedometer'
    // submenu: [
    //     {
    //         text: 'Dashbord v1',
    //         link: '/dashboard/v1'
    //     },
    //     {
    //         text: 'Dashbord v2',
    //         link: '/dashboard/v2'
    //     },
    //     {
    //         text: 'Dashbord v3',
    //         link: '/dashboard/v3'
    //     }
    // ]
};

const Dashboard2Doctor = {
    text: 'Dashboard',
    link: 'dashboard/v3',
    icon: 'icon-speedometer'
};

const Doctors = {
    text: 'Doctores',
    link: 'clinic/doctors',
    icon: 'fab fa-bity'
};

const FiscalCompanies = {
    text: 'Empresas Fiscales',
    link: 'invoicing/fiscalcompanies',
    icon: 'fab fa-bity'
};

const Patients = {
    text: 'Pacientes',
    link: 'clinic/patients',
    icon: 'fas fa-address-card'
};


const Estimations = {
    text: 'Presupuestos',
    link: 'clinic/estimations',
    icon: 'far fa-file-alt'
};

const Appointments = {
    text: 'Consultas',
    link: 'clinic/appointments',
    icon: 'fas fa-calendar-plus'
};

const DiarlyList = {
    text: 'Lista Diaria',
    link: 'diarly',
    icon: 'fas fa-clipboard-list'
};

const Agenda = {
    text: 'Agenda',
    link: 'clinic/agenda',
    icon: 'fas fa-address-book'
};

const ClinicReceipts = {
    text: 'Recibos Clinica',
    link: 'clinic/clinicreceipts',
    icon: 'fas fa-receipt'
};

const AcademicCourses = {
    text: 'Cursos',
    link: 'academic/courses',
    icon: 'fas fa-graduation-cap'
};

const AcademicDashboard = {
    text: 'Matrícula',
    link: 'academic/academicdashboard',
    icon: 'icon-speedometer'
};


const StudentsGroups = {
    text: 'Grupos',
    link: 'academic/studentgroups',
    icon: 'fas fa-user-friends'
};

const AcademicReceipts = {
    text: 'Recibos Academicos',
    link: 'academic/academicreceipts',
    icon: 'fas fa-receipt'
};

const AcademicPositiveBalance = {
    text: 'Saldos a Favor Académicos',
    link: 'academic/academicpositivebalance',
    icon: 'fas fa-balance-scale'
};

const AcademicBalance = {
    text: 'Balance Academico',
    link: 'academic/academicbalance',
    icon: 'fas fa-balance-scale'
};

const StudentBalance = {
    text: 'Balance por Estudiante',
    link: 'academic/studentbalance',
    icon: 'fas fa-balance-scale'
};

const PositiveBalance = {
    text: 'Saldos a Favor',
    link: 'clinic/positive-balance',
    icon: 'fas fa-balance-scale'
};


const Staff = {
    text: 'Staff',
    link: 'travelexpenses/staff',
    icon: 'fas icon-people'
};

const Rates = {
    text: 'Tarifas',
    link: 'travelexpenses/rates',
    icon: 'fas fa-donate'
};

const Commissions = {
    text: 'Comisiones',
    link: 'travelexpenses/commissions',
    icon: 'fab fa-avianex'
};

const IsoIndicators = {
    text: 'Indicadores ISO',
    link: 'academic/isoindicators',
    icon: 'fas fa-chart-line'
};

// const ReceiptBalance = {
//     text: 'Balance de Recibos',
//     link: 'accounting/receiptbalance',
//     icon: 'fas fa-balance-scale'
// }

const ReportsReception = {
    text: 'Administración',
    link: 'accounting/reports',
    icon: 'icon-note',
    submenu: [{
        text: 'Reporte Diario',
        link: 'accounting/reports'
    }]
};


const AcademicCharge = {
    text: 'Carga Académica',
    link: 'academic/academiccharge',
    icon: 'fas fa-graduation-cap',
    submenu: [{
        text: 'Carga Académica Grupal',
        link: 'academic/academiccharge'
    },
    {
        text: 'Carga Académica por Estudiante',
        link: 'academic/academicchargebystudent'
    }]
};

const headingEvents = {
    text: 'Eventos',
    heading: true,
};

const Events = {
    text: 'Listado de Eventos',
    link: 'events/event-catalog',
    icon: 'fas fa-calendar-check'
};

const TalkCode = {
    text: 'Códigos de Registro',
    link: 'events/talk-code',
    icon: 'fas fa-barcode'
};

const TalkCodeCheckin = {
    text: 'Asistencia en Conferencia',
    link: 'events/talk-code-checkin',
    icon: 'fas fa-check-square'
};

const TicketList = {
    text: 'Listado de Boletos',
    link: 'events/ticketlist',
    icon: 'fas fa-ticket-alt'
};

const Checkin = {
    text: 'Registro de Acceso',
    link: 'events/checkin',
    icon: 'fas fa-user-check'
};

const InRegister = {
    text: 'Registro de Participantes',
    link: 'events/in-register',
    icon: 'icon-note'
};

const EventReports = {
    text: 'Reportes de Eventos',
    link: 'events/eventreports',
    icon: 'fas fa-file-excel'
};

const StudentsInEvent = {
    text: 'Estudiantes Inscritos en Eventos',
    link: 'events/studentsinevent',
    icon: 'fas fa-user'
};

const MyTickets = {
    text: 'Mis Boletos',
    link: 'events/mytickets',
    icon: 'fas fa-ticket-alt'
};


const Reports = {
    text: 'Administración',
    link: 'accounting/reports',
    icon: 'icon-note',
    submenu: [{
        text: 'Reporte Diario',
        link: 'accounting/reports'
    },
    {
        text: 'Facturación Global',
        link: 'accounting/globalinvoice',
    },
    {
        text: 'Balance de Recibos',
        link: 'accounting/receiptsbalance',
    },
    {
        text: 'Facturas',
        link: 'accounting/invoices',
    },
    {
        text: 'Ingresos Sin Identificar',
        link: 'accounting/unidentifiedincomes',
    },
    {
        text: 'Entradas Diversas',
        link: 'accounting/generalentry',
    },
    {
        text: 'Cuentas por Cobrar',
        link: 'accounting/accountsreceivable',
    },
    {
        text: 'Referencias Bancarias',
        link: 'accounting/bankreferences',
    },]
};

const Services = {
    text: 'Servicios',
    link: '/services',
    icon: 'fas fa-briefcase-medical',
    submenu: [
        {
            text: 'Areas',
            link: 'clinic/areas'
        },
        {
            text: 'Insumos',
            link: 'clinic/supplies'
        },
        {
            text: 'Listas de Precios',
            link: 'clinic/pricelist'
        },
        {
            text: 'Tipos de Doctores',
            link: 'clinic/doctortypes'
        }
    ]
};

const headingMain = {
    text: 'General',
    heading: true
};

const clinicComponents = {
    text: 'Gestión Clínica',
    heading: true
};

const academicComponents = {
    text: 'Gestión Escolar',
    heading: true
};

const travelexpensesComponents = {
    text: 'Viáticos',
    heading: true
};

const receptionComponents = {
    text: 'Recepción',
    heading: true
};

const adminComponents = {
    text: 'Administración',
    heading: true
};

const libraryComponents = {
    text: 'Biblioteca',
    heading: true
};

const Subjects = {
    text: 'Materias',
    link: 'library/subjects',
    icon: 'fab fa-black-tie'
};

const Books = {
    text: 'Libros',
    link: 'library/books',
    icon: 'fas fa-book'
};

const Borrow = {
    text: 'Préstamos',
    link: 'library/borrows',
    icon: 'far fa-address-card'
};

const Editorial = {
    text: 'Editorial',
    link: 'library/editorials',
    icon: 'fas fa-book-open'
};

const informationComponents = {
    text: 'Informes',
    heading: true
};

const InfoCourses = {
    text: 'Oferta',
    link: 'information/infocourses',
    icon: 'far fa-calendar-plus'
};

const InfoLeads = {
    text: 'Prospectos',
    link: 'information/infoleads',
    icon: 'fa icon-call-out'
};

const Periods = {
    text: 'Ciclos',
    link: 'academic/periods',
    icon: 'fas fa-calendar-check'
};

const WorkSpaces = {
    text: 'Espacios de Trabajo',
    link: 'academic/workspaces',
    icon: 'fab fa-black-tie'
};

const Schedule = {
    text: 'Horarios de Clase Ciclo',
    link: 'academic/schedules',
    icon: 'fas fa-calendar-plus'
};

const WeekSchedule = {
    text: 'Horarios de Clase Semanal',
    link: 'academic/weekschedules',
    icon: 'fas fa-calendar-plus'
};

const Careers = {
    text: 'Carreras',
    link: 'academic/careers',
    icon: 'fas fa-book-open'
};

const Grades = {
    text: 'Grupos',
    link: 'academic/grades',
    icon: 'fas fa-book-open'
};

const DoctorProfile = {
    text: 'Perfil del Doctor',
    link: 'academic/doctorprofile',
    icon: 'fas fa-user'
};

const ProfessorProfile = {
    text: 'Perfil del Catedrático',
    link: 'academic/professorprofile',
    icon: 'fas fa-user'
};

const Professors = {
    text: 'Catedráticos',
    link: 'academic/professors',
    icon: 'fas fa-chalkboard-teacher'
};

const PeriodProfessors = {
    text: 'Catedráticos por Ciclo',
    link: 'academic/periodprofessors',
    icon: 'fas fa-chalkboard-teacher'
};

const SubjectsbyPeriod = {
    text: 'Materias por Ciclo',
    link: 'academic/periodprofessorsubjects',
    icon: 'fas fa-newspaper'
};

const ProfessorsAvailability = {
    text: 'Disponibilidad',
    link: 'academic/professorsavailability',
    icon: 'fas fa-calendar'
};

const SubjectpranningtemplatesComponent = {
    text: 'Template de Planeaciones por Materia',
    link: 'academic/subjectplanningtemplates',
    icon: 'fas fa-newspaper'
};

const GradeSubjectProfessors = {
    text: 'Catedráticos por Materia',
    link: 'academic/gradesubjectprofessors',
    icon: 'fas fa-chalkboard-teacher'
};

const PlaneationActivities = {
    text: 'Actividades en planeaciones',
    link: 'academic/planeationactivities',
    icon: 'fas fa-chalkboard-teacher'
};

const ProfessorPlaneations = {
    text: 'Planeaciones por Catedrático',
    link: 'academic/professorplaneations',
    icon: 'fas fa-chalkboard-teacher'
};

const SchoolYear = {
    text: 'Años Escolares',
    link: 'academic/schoolyears',
    icon: 'fas fa-calendar-alt'
};

const medicalrecordsComponents = {
    text: 'Expedientes',
    heading: true
};

const MedicalRecordsControl = {
    text: 'Control de Expedientes',
    link: 'medicalrecords',
    icon: 'fas fa-file'
};

const studentSchedule = {
    text: 'Mi Horario',
    link: 'academic/studentschedule',
    icon: 'fas fa-calendar'
};

const professorSchedule = {
    text: 'Mis Clases',
    link: 'academic/professorschedule',
    icon: 'fas fa-calendar'
};

const Evaluations = {
    text: 'Calificaciones',
    link: 'academic/evaluations',
    icon: 'fas fa-edit'
};


const documents = {
    text: 'Documentos Escolares',
    link: 'academic/documents',
    icon: 'fas fa-file',
    submenu: [
        {
            text: 'Tipos de Documentos',
            link: 'academic/documentsType'
        },
        {
            text: 'Tipos de Documentos por Niveles',
            link: 'academic/documents'
        },
        {
            text: 'Documentos por Estudiante',
            link: 'academic/studentdocuments'
        },
        {
            text: 'Documentos por Grupo',
            link: 'academic/studentdocumentspending'
        },
    ]
};


const academicDirection = {
    text: 'Dirección Academica',
    link: 'academic/documents',
    icon: 'fas fa-file',
    submenu: [
        {
            text: 'Indicadores ISO',
            link: 'academic/isoindicators',
        },
        {
            text: 'Periodos de Calificaciones General',
            link: 'academic/evaluationperiodgeneral'
        },
        {
            text: 'Configuración de Evaluaciones y Listas de Asistencia',
            link: 'academic/evaluationperiodsubject'
        },
        {
            text: 'Calificaciones',
            link: 'academic/evaluations',
        },
        {
            text: 'Reporte de Calificaciones',
            link: 'academic/reportcard',
        },
        {
            text: 'Resumen de Listas de Asistencia',
            link: 'academic/assistancelistresume',
        }
    ]
};

const mydocuments = {
    text: 'Mis Documentos',
    link: 'academic/studentdocuments',
    icon: 'fas fa-file',
};

const careercoordinatorsbyperiod = {
    text: 'Coordinadores',
    link: 'academic/careercoordinatorsbyperiod',
    icon: 'fas fa-chalkboard-teacher'
};

const myreportcard = {
    text: 'Mi Reporte de calificaciones',
    link: 'academic/myreportcard',
    icon: 'fas fa-file',
};

const sholControlComponents = {
    text: 'Control Escolar',
    heading: true
};

const schCGroups = {
    text: 'Grupos',
    link: 'schoolcontrol/scgroups',
    icon: 'fas fa-user-friends'
}

const schCEvaluation = {
    text: 'Calificaciones',
    link: 'schoolcontrol/scgroup-evaluations',
    icon: 'fas fa-book-open'
}

const academicRecords = {
    text: 'Historiales Académicos',
    link: 'schoolcontrol/academic-records',
    icon: 'fas fa-file'
}

const certificates = {
    text: 'Certificados',
    link: 'schoolcontrol/certificates',
    icon: 'fas fa-file'
}

const StudentBalanceDashboard = {
    text: 'Dashboard Académico',
    link: 'academic/studentbalancedashboard',
    icon: 'fas fa-balance-scale'
};

const clinicDirection = {
    text: 'Prácticas',
    link: 'clinicdirection/practices',
    icon: 'fas fa-calendar-plus',
};


export const menu = [
    headingMain,
    DashboardAdmin,
    clinicComponents,
    Agenda,
    Doctors,
    Patients,
    Estimations,
    Appointments,
    Services,
    ClinicReceipts,
    PositiveBalance,
    academicComponents,
    AcademicDashboard,
    StudentBalanceDashboard,
    AcademicCourses,
    StudentsGroups,
    AcademicReceipts,
    AcademicPositiveBalance,
    AcademicBalance,
    StudentBalance,
    SchoolYear,
    Careers,
    Periods,
    SubjectsbyPeriod,
    Grades,
    AcademicCharge,
    WorkSpaces,
    Schedule,
    WeekSchedule,
    Professors,
    PeriodProfessors,
    ProfessorProfile,
    ProfessorsAvailability,
    ProfessorPlaneations,
    SubjectpranningtemplatesComponent,
    GradeSubjectProfessors,
    PlaneationActivities,
    studentSchedule,
    professorSchedule,
    documents,
    academicDirection,
    clinicDirection,
    careercoordinatorsbyperiod,
    adminComponents,
    FiscalCompanies,
    Reports,
    sholControlComponents,
    schCGroups,
    schCEvaluation,
    academicRecords,
    certificates,
    travelexpensesComponents,
    Staff,
    Rates,
    Commissions,
    libraryComponents,
    Subjects,
    Editorial,
    Books,
    Borrow,
    informationComponents,
    InfoCourses,
    InfoLeads,
    headingEvents,
    Events,
    TicketList,
    Checkin,
    EventReports,
    StudentsInEvent,
    TalkCode,
    TalkCodeCheckin,
    MyTickets,
    InRegister,
    medicalrecordsComponents,
    MedicalRecordsControl,
];


export const menuDoctor = [
    headingMain,
    Dashboard2Doctor,
    Agenda,
    clinicComponents,
    Estimations,
    Patients,
    Appointments,
    clinicDirection,
    academicComponents,
    DoctorProfile,
    mydocuments,
    studentSchedule,
    myreportcard,
    headingEvents,
    MyTickets,
    // TalkCodeCheckin,
];

export const menuReception = [
    headingMain,
    DashboardDoctor,
    clinicComponents,
    Estimations,
    Agenda,
    Patients,
    Doctors,
    Appointments,
    Services,
    ClinicReceipts,
    adminComponents,
    ReportsReception,
];


export const menuClinicDirection = [
    clinicComponents,
    Agenda,
    Doctors,
    Patients,
    Estimations,
    Appointments,
    Services,
    clinicDirection,
    medicalrecordsComponents,
    MedicalRecordsControl
];


export const menuReceptionWithAcademic = [
    headingMain,
    DashboardDoctor,
    clinicComponents,
    Estimations,
    // Agenda,
    Patients,
    Doctors,
    Appointments,
    Services,
    ClinicReceipts,
    adminComponents,
    ReportsReception,
    academicComponents,
    AcademicCourses,
    StudentsGroups,
    AcademicReceipts,
    AcademicPositiveBalance,
    AcademicBalance,
    StudentBalance,
];

export const menuInformation = [
    clinicComponents,
    Doctors,
    Patients,
    ClinicReceipts,
    academicComponents,
    AcademicCourses,
    StudentsGroups,
    headingEvents,
    Events,
    TicketList,
    Checkin,
    EventReports,
    StudentsInEvent,
    InRegister,
    TalkCode,
];

export const menuAcademicAdmin = [
    academicComponents,
    AcademicCourses,
    StudentsGroups,
    Periods,
    WorkSpaces,
    Schedule,
    WeekSchedule,
    Grades,
];

export const menuAcademicDirection = [
    WeekSchedule,
    PeriodProfessors,
    SubjectsbyPeriod,
    ProfessorsAvailability,
    ProfessorPlaneations,
    academicDirection,
    careercoordinatorsbyperiod,
];


export const menuProfessor = [
    Dashboard2Doctor,
    academicComponents,
    ProfessorProfile,
    SubjectsbyPeriod,
    ProfessorsAvailability,
    ProfessorPlaneations,
    professorSchedule,
    Evaluations,
    headingEvents,
    MyTickets,
    TalkCodeCheckin,
];

export const menuProfessorPDoctor = [
    Dashboard2Doctor,
    Agenda,
    Patients,
    Appointments,
    academicComponents,
    ProfessorProfile,
    SubjectsbyPeriod,
    ProfessorsAvailability,
    ProfessorPlaneations,
    professorSchedule,
    Evaluations,
    headingEvents,
    MyTickets,
    TalkCodeCheckin,
];

export const menuPDoctor = [
    Dashboard2Doctor,
    Agenda,
    Patients,
    Appointments,
];

export const menuAcademicManager = [
    academicComponents,
    AcademicDashboard,
    Periods,
    WorkSpaces,
    Schedule,
    WeekSchedule,
    SchoolYear,
    Careers,
    Grades,
    AcademicCharge,
    Professors,
    PeriodProfessors,
    // ProfessorProfile,
    SubjectsbyPeriod,
    ProfessorsAvailability,
    SubjectpranningtemplatesComponent,
    GradeSubjectProfessors,
    PlaneationActivities,
    ProfessorPlaneations,
    professorSchedule,
    careercoordinatorsbyperiod,
    academicDirection,
];


export const menuMedicalRecordsAdmin = [
    clinicComponents,
    Agenda,
    Patients,
    medicalrecordsComponents,
    MedicalRecordsControl,
];


export const menuSchoolServices = [
    clinicComponents,
    Doctors,
    academicComponents,
    AcademicCourses,
    StudentsGroups,
    SchoolYear,
    Careers,
    Periods,
    SubjectsbyPeriod,
    Grades,
    AcademicCharge,
    WorkSpaces,
    Schedule,
    WeekSchedule,
    Professors,
    PeriodProfessors,
    ProfessorProfile,
    ProfessorsAvailability,
    ProfessorPlaneations,
    SubjectpranningtemplatesComponent,
    GradeSubjectProfessors,
    PlaneationActivities,
    documents,
    careercoordinatorsbyperiod
];

export const menuSchoolControl = [
    Doctors,
    Careers,
    academicDirection,
    documents,
    sholControlComponents,
    schCGroups,
    schCEvaluation,
    academicRecords,
    certificates,
]


export const menuAcademicReception = [
    academicComponents,
    AcademicCourses,
    StudentsGroups,
    AcademicReceipts,
    AcademicPositiveBalance,
    AcademicBalance,
    StudentBalance,
];

export const menuDeposit = [
    clinicDirection,
];


export const menuCEYE = [
    Agenda,
];