import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { UserService } from './user.service';
import { Observable, of } from 'rxjs';
import { MenuService } from '../core/menu/menu.service';
import { menuDoctor, menuReception, menuInformation, menuAcademicAdmin, menu } from '../routes/menu';

@Injectable({
  providedIn: 'root'
})
export class IdentityGuard implements CanActivate {
    constructor( private router: Router,
                 private userService: UserService ) {

    }
    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
      const identity = this.userService.getIdentity();
      if (identity) {
            return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }
}

@Injectable({
  providedIn: 'root'
})
export class IsStaffGuard implements CanActivate {
  constructor( private router: Router,
               private userService: UserService ) {

  }
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const userData = this.userService.getUserData();
    const isStaff  = userData.is_staff || false;
    if (isStaff) {
          return true;
      } else {
          this.router.navigate(['/home']);
          return false;
      }
  }
}

@Injectable({
  providedIn: 'root'
})
export class RedirectionGuard implements CanActivate {
  constructor( private router: Router,
               private userService: UserService ) {
  }
  canActivate(): Observable<boolean> {
    const userData = this.userService.getUserData();
    const userType  = userData.profile?.user_type || '';
    // console.log(userType);
    if (userType !== '') {
      if ((userType.id_user_type == 'admin') || (userType.id_user_type == 'accounting')) {
        this.router.navigate(['dashboard/v2']);
      } else if (userType.id_user_type == 'reception') {
        this.router.navigate(['dashboard/v1']);
      } else if (userType.id_user_type == 'doctor') {
        this.router.navigate(['dashboard/v3']);
      } else if (userType.id_user_type == 'information') {
        this.router.navigate(['events']);
      } else if (userType.id_user_type == 'academic_admin') {
        this.router.navigate(['home']);
      } else if (userType.id_user_type == 'professor') {
        this.router.navigate(['home']);
      } else if (userType.id_user_type == 'academic_manager') {
        this.router.navigate(['home']);
      } else if (userType.id_user_type == 'medicalrecords') {
        this.router.navigate(['home']);
      } else if (userType.id_user_type == 'schoolservices') {
        this.router.navigate(['home']);
      } else if (userType.id_user_type == 'academic_reception') {
        this.router.navigate(['home']);
      } else if (userType.id_user_type == 'reception_w_academic') {
        this.router.navigate(['home']);
      } else if (userType.id_user_type == 'clinic_direction') {
        this.router.navigate(['home']);
      } else if (userType.id_user_type == 'academic_direction') {
        this.router.navigate(['home']);
      } else if (userType.id_user_type == 'school_control') {
        this.router.navigate(['home']);
      }else if (userType.id_user_type == 'deposit') {
        this.router.navigate(['home']);
      } else if (userType.id_user_type == 'ceye') {
      this.router.navigate(['home']);
      }else if (userType.id_user_type == 'professor_pdoctor') {
        this.router.navigate(['home']);
      } else if (userType.id_user_type == 'pdoctor') {
      this.router.navigate(['home']);
      }
    } else {
      this.router.navigate(['login']);
    }
    return of(false);
  }
}

@Injectable({
  providedIn: 'root'
})
export class IsDoctorGuard implements CanActivate {
  constructor( private router: Router,
               private userService: UserService ) {
  }
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const userData = this.userService.getUserData();
    const userType  = userData.profile?.user_type || '';
    if ((userType.id_user_type == 'doctor')  || (userType.id_user_type == 'reception')) {
          return true;
      } else {
          this.router.navigate(['/home']);
          return false;
      }
  }
}


@Injectable({
  providedIn: 'root'
})
export class IsProfileGuard implements CanActivate {
  constructor( private router: Router,
               private route: ActivatedRoute,
               private userService: UserService,
               private menuService: MenuService ) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // let men;
    // const url = state.url.substring(1);
    // let cango = 0;
    // let ud = this.userService.getUserData();
    // let type = ud?.profile?.user_type?.id_user_type || '';
    // if (type === 'doctor') {
    //     men = menuDoctor;
    // } else if(type === 'reception') {
    //     men = menuReception;
    // } else if(type === 'information') {
    //     men = menuInformation;
    // } else if(type === 'academic_admin') {
    //     men = menuAcademicAdmin;
    // } else {
    //     men = menu;
    // }
    // men.forEach(me => {
    //   if(me.link == url) {
    //     cango += 1;
    //   }
    //   if(me.submenu) {
    //     me.submenu.forEach(sm => {
    //       if(sm.link == url) {
    //         cango += 1;
    //       }
    //     })
    //   }
    // });
    // return cango > 0;
    // console.log(this.route?.routeConfig?.children);

    return true;
  }
}
